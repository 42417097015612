<template lang="pug">
  .con(v-if="!$store.state.pcshow")
    img(src='../assets/img/img47.png')
    .btngroup.clearfix
      router-link(to="/thothAPP" :class="$route.path === '/thothAPP'? 'active': ''")
        .companyIntro
          p Thoth
      //- router-link(to="/ProductCenter" :class="$route.path === '/ProductCenter'? 'active': ''")
      //-   .companyIntro
      //-     p 亿联工坊
      router-link(to="/bxcApp" :class="$route.path === '/bxcApp'? 'active': ''")
        .companyIntro
          p 彼仙草
      router-link(to="/goods" :class="$route.path === '/goods'? 'active': ''")
        .companyIntro
          p 食品药品
    .con3
      .ul.clearfix
        .li
          img(src="../assets/img/img48.png")
          h4 六味地黄丸
          p 成分：熟地黄、酒萸肉、 牡丹皮、山药、茯苓、泽 泻。辅料为蜂蜜。
          p 功能：滋阴补肾。用于肾阴亏损，头晕耳鸣，腰膝酸软，骨蒸潮热，盗汗遗精。
        .li
          img(src="../assets/img/img49.png")
          h4 五子衍宗丸
          p 成分：枸杞子、菟丝子(炒)、 覆盆子、五味子（蒸）、 盐车前子。辅料为赋形剂 蜂蜜。
          p 功能：滋阴补肾。用于肾阴亏损，头晕耳鸣，腰膝酸软，骨蒸潮热，盗汗遗精。
        .li
          img(src="../assets/img/img50.png")
          h4 知柏地黄丸
          p 成分：知母、黄柏、熟地 黄、山茱萸（制）、牡丹 皮、山药、茯苓、泽泻。 辅料为蜂蜜。
          p 功能：滋阴降火。用于阴虚火旺，潮热盗汗，口干咽痛，耳鸣遗精，小便短赤。
  .productCenter(v-else)
</template>

<script>
export default{
  name: 'BxcApp',
  data() {
    return {
      
    }
  }
}
</script>

<style scoped lang="stylus">
  @media screen and (max-width: 960px)
    .con
      width: 100%;
      background: #F5F5F5;
      border-bottom: 0.35rem solid #eaeaea
      >img
        display: block
        width:7.5rem;
        height: 4rem;
    .btngroup
        width: 6.8rem;
        padding-left:0.35rem;
        padding-right:0.35rem; 
        padding-bottom:0.35rem;
        a
          display: block;
          width: 2rem;
          height: 0.68rem;
          background: #FFFFFF;
          float: left
          margin-top: 0.3rem;
          margin-right: 0.4rem;
          font-size: 0.26rem;
          font-family: Source Han Sans CN;
          font-weight: 400;
          color: #333333;
          line-height: 0.68rem;
          text-align: center; 
        a.active
          background: #0085D0;
          color: #ffffff;
        a:nth-child(3)
          margin-right:0;
    .conmian
      display: block;
      width: calc( 100% - 0.88rem );
      padding-left: 0.88rem;
      background: #fff;
      >img
        display: block
        float: left
        width: 1.59rem
        height: 3.05rem
        padding-bottom: 0.49rem
        padding-top: 0.49rem
      .conright
        float: left
        h2
          padding-left: 0.4rem;
          padding-top: 1.47rem;
          font-size: 0.3rem;
          font-family: Source Han Sans CN;
          font-weight: 500;
          color: #333333;
          line-height: 0.45rem;
        p
          width: 3.7rem;
          padding-left: 0.4rem;
          // height: 0.24rem;
          font-size: 0.24rem;
          font-family: Source Han Sans CN;
          font-weight: 400;
          color: #333333;
          line-height: 0.45rem;
          padding-top: 0.1rem
    .conmainback
      background: #eaeaea
      .conright
        h2
          padding-left: 0rem;
          padding-right: 1.67rem;
        p
          padding-left: 0rem;
          padding-right: 1.67rem;
  .con3{
      display: block;
      width: calc( 100% - 0.68rem );
      padding: 0 0.34rem;
      /* padding-bottom: 0.32rem; */
      border-bottom: 0.32rem solid #F5F5F5;
    }
    .con3 .h2{
      padding-top: 0.55rem;
      padding-bottom: 0.10rem;
      font-size: 0.26rem;
      font-family: PingFang SC;
      font-weight: bold;
      color: #333333;
      line-height: 0.39rem;
    }
    .con3 .p{
      font-size: 0.24rem;
      font-family: Source Han Sans CN;
      font-weight: 400;
      color: #666666;
      line-height: 0.45rem;
      text-align: center;
    }
    .con3 .ul{
      padding-top: 0.28rem
    }
    .con3 .ul .li{
      display: block;
      float: left;
      width: 3.22rem;
      box-shadow: 0px 4px 14px 0px rgba(0, 0, 0, 0.2);
      border-radius: 0px 0px 0.1rem 0.1rem;
      height:5.83rem;
      margin-bottom:0.33rem;
      position: relative;
    }
    .con3 .ul .li:nth-child(2n-1){
      margin-right:0.38rem
    }
    .con3 .ul .li>img{
      display: block;
      width: 3.22rem;
      height: 2.02rem;
    }
    .con3 .ul .li>h4{
      font-size: 0.3rem;
      font-family: Source Han Sans CN;
      font-weight: bold;
      color: #333333;
      padding:0.19rem 0 0.1rem 0.23rem
    }
    .con3 .ul .li>p{
      font-size: 0.24rem;
      font-family: Source Han Sans CN;
      font-weight: 400;
      color: #333333;
      line-height: 0.34rem;
      padding:0rem 0.24rem 0rem 0.24rem
    }
</style>  